.Contactsmobile {
  /* display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; */
  width: 100%;
  height: 100%;
  padding: 50px;
  background: url("../.././public/images/Contact\ bg.png");
  background-position: center right;
  background-repeat: no-repeat;
  background-size: 100%;
}

.Contactcontainer {
  margin: auto;
  padding: 0 100px;
  /* display: flex;
    justify-content: center;
    align-items: center; */
  height: 100%;
}

.clientsimg {
  max-width: 5%;
  margin-right: 10px;
}

.contacttitle {
  color: #ed1c24;
  font-size: 32px;
  font-family: futuramedium;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.para1 {
  color: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 18px;
  font-family: futuramedium;
  text-align: justify;
}

.address1 {
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.addressimg {
  max-width: 15%;
  margin-right: 15px;
}

.addresstitle {
  color: #ed1c24;
  font-size: 20px;
  font-family: futuramedium;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.add1 {
  color: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 18px;
  font-family: futuramedium;
  text-align: justify;
}

.contactbrand {
  font-size: 30px;
  font-family: futuramedium;
  color: #fff;
  text-align: justify;
}

.brand {
  color: #ed1c24;
}

.Servicescontent {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.box {
  /* position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); */
  width: 400px;
  padding: 0 55px;
  /* background: rgba(0, 0, 0, 0.4);
      box-sizing: border-box;
      box-shadow: 0 15px 25px rgba(0, 0, 0, 0.5); */
  border-radius: 10px;
}
.box h2 {
  margin: 0 0 30px;
  padding: 0;
  color: #fff;
  text-align: center;
}

.box .inputBox {
  position: relative;
}
.box .inputBox input {
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  letter-spacing: 1px;
  margin-bottom: 10px;
  border: none;
  border-bottom: 2px solid #fff;
  outline: none;
  background: transparent;
}

input::placeholder {
  color: #fff;
}

select {
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: #ffffff;
  letter-spacing: 1px;
  margin-bottom: 10px;
  border: none;
  border-bottom: 2px solid #fff;
  outline: none;
  background: transparent;
}

option {
  /* width: 100%;
      padding: 10px 0;
      font-size: 16px;
      color: #fff;
      letter-spacing: 1px; */
  margin-bottom: 10px;
  border: none;
  border-bottom: 2px solid #fff;
  outline: none;
  background: #000;
}

.box .inputBox label {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  pointer-events: none;
  font-family: futuramedium;
  /* transition: 0.5s;
      animation: LabelOnLoad 1s forwards 0s ease; */
}
/* @keyframes LabelOnLoad {
      0% {
        transform: rotate(0) translateY(-19px);
        opacity: 0;
      }
      100% {
        transform: rotate(0) translateY(0);
        opacity: 1;
      }
    } */
/* .box .inputBox input:focus ~ label,
    .box .inputBox input:valid ~ label {
      top: -19px;
      left: 0;
      color: #03a9f4;
      font-size: 12px;
    } */
.box input[type="submit"] {
  background: transparent;
  border: none;
  outline: none;
  color: #fff;
  background: #ed1c24;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: futuramedium;
}
.box input[type="submit"]:hover {
  /* background-color: rgba(3, 169, 244, 0.7); */
  border: 1px solid #fff;
  color: #ed1c24;
  background: transparent;
}

@media (max-width: 767px) {
  /* Add the following style to ensure the body takes 100% height */

  .box {
    width: 100%;
    padding: 0 35px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    height: fit-content;
  }
  .Contactsmobile {
    padding: 10px;
    background: url("../.././public/images/Contact\ Mobile.svg");
    height: 100%;
    /* overflow-y: auto; */
    margin-top: 15%;
  }
  .Contactcontainer {
    padding: 0;
    height: fit-content;
    position: absolute;
    top: 0;
    margin: auto;
    bottom: 0;
  }
  .Servicescontent1 {
    padding: 0 30px;
    position: relative;
    top: 15%;
  }
  .para1 {
    font-size: 26px;
  }

  .sub {
    margin-bottom: 30px;
  }

  .box .inputBox input {
    /* padding: 20px 0; */
    margin-bottom: 5%;
  }
  select {
    margin-bottom: 5%;
  }
  .inputBox {
    margin-bottom: 5%;
  }
  .add1 {
    font-size: 16px;
    text-align: left;
  }
  .addressimg {
    max-width: 30%;
  }
  .addresstitle {
    font-size: 26px;
  }
  .clientsimg {
    max-width: 8%;
  }
}
@media only screen and (max-width: 400px) {
  .addressimg {
    max-width: 25%;
  }
  .para1 {
    font-size: 20px;
    text-decoration: none !important;
  }
}
