.postion-center {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.width-fit {
  max-width: 1170px;
}
.slider-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.7;
}
.slider-arrow:hover {
  cursor: pointer;
  opacity: 1;
}
.slider-dots {
  gap: 7px;
  font-size: 12px;
  color: #fff;
  position: fixed;
  margin: auto;
  left: 0 !important;
  right: 0;
  bottom: 10% !important;
}
.slider-dots .active {
  color: #ed1c24;
}
.slider-dots > i {
  cursor: pointer;
}
.bottom-2 {
  bottom: -4rem;
  left: 5rem;
}
.slider-content-container {
  height: 60%;
}
.slider-content {
  height: auto;
  padding-left: 45px;
}
.title {
  font-weight: 500 !important;
  color: #ed1c24;
  font-size: 40px;
}
.p-content {
  font-size: 16px;
  color: #fff;
  text-align: justify;
  font-family: futuramedium;
}
.gif-size {
  max-width: 5%;
}
.bg-pos {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transition: opacity 1.5s, -webkit-transform 2.5s;
  transition: opacity 1.5s, -webkit-transform 2.5s;
  transition: transform 2.5s, opacity 1.5s;
  transition: transform 2.5s, opacity 1.5s, -webkit-transform 2.5s;
  -webkit-transform: scale(1.15);
  -ms-transform: scale(1.15);
  transform: scale(1.15);
  opacity: 0;
  -o-object-fit: cover;
  object-fit: cover;
  will-change: transform, opacity;
}
.bg-pos.active-bg {
  transform: scale(1);
  opacity: 1;
}
.liservices {
  font-size: 22px;
}

@media (max-width: 767px) {
  .p-content {
    font-size: 12px;
  }
  .liservices p {
    font-size: 16px;
  }
  .bottom-2 {
    bottom: -2rem !important;
    left: 5.5rem !important;
  }
  .slider-dots {
    gap: 7px;
    font-size: 8px;
    color: #fff;
  }
  .postion-center {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .width-fit {
    max-width: 1170px;
  }
  .slider-arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.7;
  }
  .slider-arrow:hover {
    cursor: pointer;
    opacity: 1;
  }
  .slider-dots {
    gap: 7px;
    font-size: 12px;
    color: #fff;
    margin-top: 30px;
  }
  .slider-dots .active {
    color: #ed1c24;
  }
  .slider-dots > i {
    cursor: pointer;
  }

  .slider-content-container {
    height: 60%;
    margin-top: 220px;
  }
  .slider-content {
    height: auto;
  }
  .title {
    font-weight: 500 !important;
    color: #ed1c24;
    font-size: 30px;
  }
  .p-content {
    font-size: 16px;
    color: #fff;
    text-align: justify;
    font-family: futuramedium;
  }
  .gif-size {
    max-width: 5%;
  }
  .bg-image {
    background-position: center right !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    width: 100%;
    height: 100%;
    position: relative;
    transition: background 0.8s linear;
  }
  .bg1 {
    background: url("../.././public/images/Services\ bg\ 1.png");
  }
  .bg2 {
    background: url("../.././public/images/Services\ bg\ 2.png");
  }
  .bg3 {
    background: url("../.././public/images/Services\ bg\ 3.png");
  }
  .bg4 {
    background: url("../.././public/images/Services\ bg\ 3.png");
  }

  .liservices {
    font-size: 14px;
  }

  .postion-center {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .width-fit {
    max-width: 1170px;
  }
  .slider-arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.7;
  }
  .slider-arrow:hover {
    cursor: pointer;
    opacity: 1;
  }
  .slider-dots {
    gap: 7px;
    font-size: 8px;
    color: #fff;
    position: fixed;
    margin: auto;
    left: 0 !important;
    right: 0;
    bottom: 15% !important;
  }
  .slider-dots .active {
    color: #ed1c24;
  }
  .slider-dots > i {
    cursor: pointer;
  }

  .slider-content-container {
    height: 60%;
  }
  .slider-content {
    height: auto;
  }
  .title {
    font-weight: 500 !important;
    color: #ed1c24;
    font-size: 40px;
  }
  .p-content {
    font-size: 14px;
    color: #fff;
    text-align: justify;
    font-family: futuramedium;
  }
  .slider-content {
    margin-top: 0px;
    padding-left: 0;
    margin-bottom: 5rem;
  }
  .gif-size {
    max-width: 5%;
  }
  .bg-image {
    background-position: center right !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    width: 100%;
    height: 100%;
    position: relative;
    transition: background 0.8s linear;
  }
  .bg1 {
    background: url("../.././public/images/Services\ bg\ 1.png");
  }
  .bg2 {
    background: url("../.././public/images/Services\ bg\ 2.png");
  }
  .bg3 {
    background: url("../.././public/images/Services\ bg\ 3.png");
  }
  .bg4 {
    background: url("../.././public/images/Services\ bg\ 3.png");
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .title {
    font-size: 30px;
  }
  .p-content {
    font-size: 14px;
  }
  .liservices {
    font-size: 14px;
  }
  .slider-content-container {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 400px) {
  .slider-content-container {
    margin-top: 120px;
  }
  .title {
    font-size: 30px;
  }
  .slider-content {
    margin-top: 0px;
    padding-left: 0;
    margin-bottom: 5rem;
  }
}

@media (min-width: 1200px) {
  .p-content {
    font-size: 18px; /* Adjust as needed */
  }
  .liservices {
    font-size: 18px; /* Adjust as needed */
  }
  .gif-size {
    max-width: 4%;
  }
  /* Add any other styles specific to extra-large screens */
}

/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
}

/* Laptop */
@media only screen and (min-width: 1024px) and (max-width: 1365px) {
}

/* Larger screens */
@media only screen and (min-width: 1366px) {
  .title {
    font-size: 40px;
  }
  .p-content {
    font-size: 18px;
  }
  .liservices {
    font-size: 18px;
  }
}
