@font-face {
  font-family: futuramedium;
  src: url("/futura/futura\ medium\ bt.ttf");
}

.overlaycontent {
  display: flex;
  justify-content: center;
  align-items: end;
  margin: auto;
  width: 100%;
  height: 100%;
  position: relative;

  /* background: url("../.././public/images/vintage-film-projector-film-screening.jpg");
    background-position: center right;
    background-repeat: no-repeat;
    background-size: cover; */
}

.contentMobile {
  display: none;
}
/* 
.show {
  opacity: 1;
  filter: blur(0);
  transform: translateX(0);
} */

.firsttitle {
  font-family: Futura;
  font-size: 34px;
  color: #fff;
  text-align: justify;
  margin-bottom: 0;
  line-height: 50px;
}

.story {
  font-family: Futura;
  font-size: 34px;
  color: #ed1c24;
  text-align: justify;
  margin-bottom: 0;
  line-height: 50px;
  font-weight: 500;
}

.secondtitle {
  font-family: futuramedium;
  font-size: 54px;
  color: #fff;
  text-align: justify;
  line-height: 85px;
  margin-bottom: 0;
}

.thirdtitle {
  font-family: Futura;
  font-size: 56px;
  color: #fff;
  text-align: justify;
  font-weight: bold;
}

.red {
  color: #ed1c24;
}

.contentTitle {
  position: relative;
  top: -45px;
  left: 160px;
}

@media (max-width: 856px) {
  .content {
    position: relative;
    top: 30% !important;
    /* margin-bottom: 2%; */
  }

  .contentTitle {
    display: none;
  }

  .contentMobile {
    display: block;
    font-family: Futura;
    font-size: 22px;
    font-weight: 500;
    color: #fff;
    text-align: justify;
    padding: 0;
    margin-bottom: 20px;
    line-height: 30px;
    width: 100%;
    height: 100%;
  }

  .story {
    font-size: 24px;
  }
  .firsttitle {
    font-size: 16px;
    /* line-height: 5px; */
    font-weight: 500;
  }

  .secondtitle {
    font-size: 32px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 12px;
  }

  .thirdtitle {
    font-size: 34px;
    font-weight: 500;
    margin-bottom: 0;
  }
}

/* tab */

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .content {
    position: relative;
    top: -20%;
    left: 10%;
    /* margin-bottom: 2%; */
  }

  .firsttitle {
    font-size: 20px;
    /* line-height: 5px; */
    font-weight: 500;
  }
  .secondtitle {
    font-size: 34px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 12px;
  }
  .thirdtitle {
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 0;
    line-height: 40px;
  }
}

@media only screen and (max-width: 400px) {
  .content {
    position: relative;
    bottom: 10%;
    padding: 0;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1365px) {
  .content {
    position: relative;
    top: 0%;
    left: -12%;
    /* margin-bottom: 2%; */
  }
  .firsttitle {
    font-size: 30px;
  }
  .secondtitle {
    font-size: 54px;
    font-weight: 500;
    line-height: 70px;
    margin-bottom: 12px;
  }
  .thirdtitle {
    font-size: 54px;
    font-weight: 600;
    margin-bottom: 0;
    line-height: 70px;
  }
  .contentTitle {
    top: -75px;
    left: 298px;
  }
  
}



@media only screen and (min-width: 1366px) {
  
}