.overallclients {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 50px;
  background: url("../.././public/images/Clients\ bg.png");
  /* background: url("../.././public/images/Clients\ Mobile.svg"); */
  background-position: center right;
  background-repeat: no-repeat;
  background-size: cover;
  /* display: block; */
}

.Services {
  margin: 15px;
  padding: 0 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.clientsimg {
  max-width: 100%;
  margin-bottom: 10%;
}

.clientlogo {
  max-width: 70% !important;
}

.Servicescontent {
  padding: 0 16px;
}

.clients {
  display: none;
}

/* Mobile */
@media only screen and (max-width: 856px) {
  .clients {
    display: block;
    height: 100%;
    margin-top: -100px;
    background: url("../.././public/images/client\ bg.png") !important;
  }
  .overallclients {
    display: none !important;
  }
  .p-content {
    font-size: 14px;
    color: #fff;
    text-align: left;
    font-family: futuramedium;
  }
  .clientsimg {
    margin-bottom: 0%;
    max-width: 60%;
    margin-top: 75px;
  }
}
@media only screen and (max-width: 856px) and (min-width: 480px){
  .clients {
    background: url("../.././public/images/Clients\ mini.png") !important;
    background-size: cover !important;
  }
}

@media only screen and (min-width: 856px) and (max-width: 1023px) {
  .overallclients {
    background: url("../.././public/images/Clients\ bg.png");
    background-size: 120% 100% !important;
    background-position: right !important;
    /* background: url("../.././public/images/Clients\ Mobile.svg"); */

    /* display: block; */
  }
  .clientlogo {
    max-width: 80% !important;
  }
}

/* Tablet */
/* @media only screen and (min-width: 768px) and (max-width: 1023px) {
  .overallclients {
    background: url("../.././public/images/Clients\ bg.png");
  }
} */

/* Laptop */
/* @media only screen and (min-width: 1024px) and (max-width: 1365px) {
  .overallclients {
    background: url("../.././public/images/Clients\ bg.png");
  }
} */

@media only screen and (max-width: 400px) {
  .Servicescontent {
    padding: 0;
  }
  .clientsimg {
    max-width: 55%;
  }
}

/* Larger screens */
@media only screen and (min-width: 1366px) {
  .clientsimg {
    max-width: 100%;
    width: 100%;
    height: 100px;
  }
  /* .overallclients {
    background: url("../.././public/images/Clients\ bg.png");
  } */
}
