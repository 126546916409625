.about {
  display: flex;
  justify-content: center;
  align-items: end;
  margin: auto;
  width: 100%;
  height: 100%;
  position: relative;
}

.abouttitle {
  font-family: Futura;
  font-size: 32px;
  font-weight: 600;
  color: #fff;
  text-align: justify;
  margin-bottom: 20px;
  line-height: 45px;
  position: absolute;
  bottom: 142px;
  left: 19px;
  width: 100rem;
  height: 100%;
}
.abouttitlemobile {
  display: none;
}
.sim {
  font-family: Futura;
  font-size: 32px;
  font-weight: 600;
  color: #ed1c24;
  text-align: justify;
  margin-bottom: 20px;
  line-height: 45px;
}

@media (max-width: 767px) {
  .abouttitle {
    display: none;
  }
  .abouttitlemobile {
    display: block;
    font-family: Futura;
    font-size: 22px;
    font-weight: 500;
    color: #fff;
    text-align: justify;
    margin-bottom: 20px;
    line-height: 30px;
    width: 100%;
    height: 100%;
  }
  .sim {
    font-size: 24px;
    line-height: 30px;
  }

  .aboutcontent {
    position: relative;
    top: 30%;
    /* left: 19px; */
  }
}

@media only screen and (max-width: 400px) {
  .aboutcontent {
    position: relative;
    /* top: 33%; */
    bottom:10%;
    padding:0;
    /* left: 19px; */
  }
}

/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 1023px) {

  .abouttitle {
    font-family: Futura;
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    text-align: justify;
    margin-bottom: 20px;
    line-height: 20px;
    position: absolute;
    bottom: 128px;
    left: 1px;
    width: 100rem;
    height: 100%;
  }
  .sim {
    font-size: 18px;
    line-height: 30px;
  }
}

/* Laptop */
@media only screen and (min-width: 1024px) and (max-width: 1365px) {
}

/* Larger screens */
@media only screen and (min-width: 1366px) and (max-width: 2200px) {
  .abouttitle {
    font-size: 34px;
    bottom: 211px;
  }
}
