@import url("https://fonts.googleapis.com/css2?family=Bubblegum+Sans&family=Inter:wght@600&family=Montserrat&family=Nunito&family=Open+Sans&family=Poppins&display=swap");

.logo {
  max-width: 100%;
  padding-left: 10px;
}

.number {
  color: #fff;
  margin-left: 5px;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  padding-right: 20px;
}
.number:hover {
  color: #fff;
  text-decoration: none;
}

.toggle {
  display: flex;
  justify-content: end;
  align-items: center;
}

.navbarcollapse {
  margin-left: -180px;
}

.navbarover {
  padding: 10px 0;
}

/* #navbarSupportedContent{
  background-color: #000000c2;
} */

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .collapsenav {
    background-color: #000000c2;
  }
}

@media (max-width: 678px) {
  .number {
    display: none !important;
  }
}

@media (max-width: 400px) {
  /* .navbarback {
    background-color: #000000c2;
  } */

  .collapsenav {
    background-color: #000000c2;
  }
  .number {
    display: none !important;
  }
}
