@font-face {
  font-family: futuramedium;
  src: url("../../futura/futura\ medium\ bt.ttf");
}

.slidershow {
  background: url("../.././public/images/Testimonial\ bg.png");
  /* background-position: center right; */
  background-repeat: no-repeat;
  background-size: 100%;
  width: 100%;
  height: 100%;
  position: relative;
}

.arrow {
  border-radius: 0 !important;
  padding: 10px 18px !important;
}

.arrow:hover {
  background-color: #ed1c24;
  border: 1px solid #ed1c24;
}

@media (max-width: 856px) {
  .arrow {
    padding: 5px 10px !important;
  }
  .slidershow {
    background: url("../.././public/images/testimonial 1.png");
    /* background-position: center right; */
    background-repeat: no-repeat;
    background-size: 100%;
    width: 100%;
    height: 100%;
    position: relative;
    bottom: 50px;
  }
  .Servicescontent1 {
    margin-top: 500px;
  }
}

/* 3:4 ratio aspect */
@media only screen and (max-width: 856px) and (min-width: 480px) {
  .slidershow {
    background: url("../.././public/images/Testimonials\ mini.png");
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .outer {
    width: 550px !important;
  }
  .arrow {
    border-radius: 0 !important;
    padding: 5px 10px !important;
  }
  .slidershow {
    background: url("../.././public/images/Testimonial\ bg.png");
    /* background-position: center right; */
    background-size: 120% 100% !important;
    background-position: right !important;
  }
}

@media only screen and (max-width: 400px) {
  /* .slidershow{
    bottom:10%;
  } */
}
