@font-face {
  font-family: futuramedium;
  src: url("../futura/futura\ medium\ bt.ttf");
}

body {
  margin: 0;
  background-color: #000;
  overflow: hidden;
}

.navbar-brand {
  max-width: 10%;
}

.swiper-container {
  width: 100%;
  height: 100vh;
  position: relative;
}
.swiper-container .slider {
  min-height: 50vh;
}
.swiper-container .slider .swiper-slide {
  background: #000;
  overflow: hidden;
  position: relative;
  height: 100%;
  text-align: center;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper-container .slider .swiper-slide .slide-inner {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

/* .scale-in {
  background-size: 100%;
} */
/* .scale-in {
  transform: scale(1.5);
  transition: transform 1s ease;
} */

.zoomed-image {
  transform: scale(1.2);
  transition: transform 1.2s ease;
}

/* .zoomed-image {
  background-size: 120%;
  transition: background-size 1.5s ease;
} */

/* .normal-image {
  background-size: 100%;
} */

/* .swiper-container .slider .swiper-slide .slide-inner img {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
} */
.swiper-container .swiper-pagination-bullet {
  background-color: rgba(0, 0, 0, 0);
}
.swiper-container .swiper-pagination {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 7em;
  position: absolute;
  z-index: 3;
  right: 0;
}
.swiper-container .swiper-pagination span {
  margin-bottom: 3em;
  font-family: "Montserrat", sans-serif;
  font-size: 0.8rem;
  letter-spacing: 0.07em;
  color: #000;
}
.swiper-container .swiper-pagination span:focus {
  outline: none;
}

#content {
  text-align: center;
  color: white;
  padding: 200px 0;
  background: #002020;
}

/* Vertical progress bar styles */
.progress-bar-container {
  position: fixed;
  top: 0;
  right: 0;
  width: 4px; /* Adjust the width as needed */
  height: 100%;
  background-color: #333;
  opacity: 0.5;
}

.progress-bar {
  width: 100%;
  height: 0;
  background-color: #007bff; /* Progress bar color */
  transition: height 0.3s ease-in-out;
}

.navbar-nav .nav-link {
  color: #fff;
  margin: 0 15px;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}
.navbar-nav .nav-link:hover {
  color: #ed1c24;
}
.nav-item .active {
  color: #ed1c24;
}

.zoom-anim {
  /* -webkit-animation: zoomin 3s ease-in;
  animation: zoomin 3s ease-in; */
  /* transition: all 1s ease-in; */
  -webkit-animation: zoomin 5s cubic-bezier(0.55, 0.085, 0, 0.99);
  -moz-animation: zoomin 5s cubic-bezier(0.55, 0.085, 0, 0.99);
  -o-animation: zoomin 5s cubic-bezier(0.55, 0.085, 0, 0.99);
  animation: zoomin 5s cubic-bezier(0.55, 0.085, 0, 0.99);
  -webkit-transition: all 1s cubic-bezier(0.55, 0.085, 0, 0.99);
  -moz-transition: all 1s cubic-bezier(0.55, 0.085, 0, 0.99);
  -o-transition: all 1s cubic-bezier(0.55, 0.085, 0, 0.99);
  transition: all 1s cubic-bezier(0.55, 0.085, 0, 0.99);
  -webkit-transition-timing-function: cubic-bezier(0.55, 0.085, 0, 0.99);
  -moz-transition-timing-function: cubic-bezier(0.55, 0.085, 0, 0.99);
  -o-transition-timing-function: cubic-bezier(0.55, 0.085, 0, 0.99);
  transition-timing-function: cubic-bezier(0.55, 0.085, 0, 0.99);
  overflow: hidden;
}

@-webkit-keyframes zoomin {
  0% {
    background-size: 110%;
  }
  100% {
    background-size: 100%;
  }
}
@keyframes zoomin {
  0% {
    background-size: 110%;
  }
  100% {
    background-size: 100%;
  }
}

/* Testimonial */
/* .slideshow-container {
  position: relative;
  background: #000;
  width: 100%;
  height: 100vh;
} */

/* Slides */
.slide-outer-container {
  left: 48%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
.inner-slide {
  position: absolute;
  top: 30%;
}
/* Next & previous buttons */
.left-side {
  padding: 0px 100px;
}

.prev {
  color: #fff;
}

.next {
  color: #fff;
}

a.prev,
a.next {
  color: #fff;
}
.prev,
.next {
  cursor: pointer;
  width: auto;
  /* margin-top: -30px; */
  padding: 4px 15px;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  border: 1px solid #fff;
  border-radius: 2px;
  -webkit-user-select: none;
  user-select: none;
  margin-top: 5px;
}

/* Position the "next button" to the right */
.next {
  margin-left: 10px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  /* background-color: rgba(0, 0, 0, 0.8); */
  color: white;
}

.apostropheContainer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 15%;
  padding: 10px 0px;
}
.apostropheImg {
  width: 4rem;
}

.testimonial-Details {
}
.detailsInner {
  display: flex;
  flex-direction: column;
  justify-content: end;
  height: 100%;
}
.detailsHeading {
  font-size: 18px;
  margin-bottom: 8%;
  color: #fff;
  font-weight: 700;
  text-align: left;
  font-family: futuramedium;
}
.subText1 {
  font-size: 14px;
  text-align: left;
  color: #fff;
  margin-bottom: 0;
  font-family: futuramedium;
}

a:not([href]):not([tabindex]),
a:not([href]):not([tabindex]) {
  color: #fff;
}
a:not([href]):not([tabindex]):focus,
a:not([href]):not([tabindex]):hover {
  color: #fff;
}

/* .hidden {
  opacity: 0;
  filter: blur(5px);
  transform: translateY(100%);
  transition: all 1s;
}

.show {
  opacity: 1;
  filter: blur(0);
  transform: translateX(0);
} */

.arrow-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: -60px;
  /* padding-top: 2rem;
  padding-left: 8rem; */
}

.fa-angle-left,
.fa-angle-right {
  font-size: 20px;
}

/* .testi-container {
  display: grid;
  margin: 0 auto;
  max-width: 1200px;
  text-align: left;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  
} */

.testi-comment {
  font-family: futuramedium;
  font-size: 20px;
  line-height: 30px;
  text-align: left;
}
.font-style-r {
  font-family: futura;
  font-size: 16px;
  margin: 0;
  text-align: left;
  text-wrap: nowrap;
}

.sflogo {
  max-width: 100%;
}

.content-over {
  padding-top: 130px;
  display: flex;
  justify-content: end;
  flex-direction: column;
  align-items: flex-start;
}

.testi-person {
  font-size: 28px;
  text-align: left;
  text-wrap: nowrap !important;
}

/* .testi-comment {
  font-size: 1.5rem;
  line-height: 1.2;
} */

.overllimg {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.fixed-height {
  width: 100%;
  height: 20rem;
}

.navbar-toggler-icon {
  color: #fff;
}

.mobileview {
  display: none;
}

/* Mobile Devices (up to 767px) */
@media (max-width: 767px) {
  .navbar-brand {
    max-width: 30%;
  }
  .content-over {
    padding-top: 5px;
  }
  .testi-comment {
    font-size: 14px;
  }
  .fixed-height {
    width: 22rem;
  }
  .slide-outer-container {
    top: 50%;
  }
  /* Adjust styles for mobile devices here */

  .swiper-container {
    height: 100vh; /* Reduce height for mobile */
  }

  .swiper-container .slider .swiper-slide {
    font-size: 16px; /* Adjust font size for mobile */
    height: 100%;
  }
  .testi-container {
    width: 20rem;
    height: 360px;
  }
  .font-style-m {
    font-size: 14px;
  }

  .overllimg {
    margin-bottom: 30px;
  }
  .apostropheImg {
    width: 3rem;
  }
  .arrow-container {
    padding-top: 15px;
    padding-left: 0;
    bottom: -90px;
  }
  .sflogo {
    /* max-width: 70%; */
    width: 260px;
    height: auto;
  }

  .align-self-end {
    padding-left: 0px;
  }
  .fa-angle-left,
  .fa-angle-right {
    font-size: 16px;
  }
  .mobileview {
    display: block;
  }

  /* Add any other mobile-specific styles here */
}

/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .swiper-container {
    height: 100vh; /* Restore original height for laptops and larger screens */
  }
  .mobileview {
    display: none;
  }
  .testi-comment {
    font-size: 14px !important;
  }
  .testi-person {
    font-size: 14px;
    text-wrap: nowrap !important;
  }
  .font-style-r {
    font-size: 14px;
    text-wrap: nowrap !important;
  }

  .slide-outer-container {
    width: 600px;
  }
  .apostropheImg {
    width: 3rem;
  }
  .arrow-container {
    position: fixed;
    top: 75%;
    z-index: 100 !important;
  }
  .content-over {
    padding-top: 100px;
  }
  .sflogo {
    max-width: 115%;
  }
}

/* Laptop */
@media only screen and (min-width: 1024px) and (max-width: 1365px) {
  .swiper-container {
    height: 100vh; /* Restore original height for laptops and larger screens */
  }
  .mobileview {
    display: none;
  }
}

/* Larger screens */
@media only screen and (min-width: 1366px) {
  .swiper-container {
    height: 100vh; /* Restore original height for laptops and larger screens */
  }
  .mobileview {
    display: none;
  }
}

@media only screen and (max-width: 400px) {
  .slide-outer-container {
    top: 48%;
  }
}
